module.exports = {
    seo: {
        title : "מאמר - כיצד רושמים פטנט - Jenko",
        description:  ""
      },
  intro: {
      title: "כיצד רושמים פטנט"
  },
  articleHeader: {
      desc: "אחד הנושאים הפופולריים ביותר בקרב יזמים שמעוניינים לפתח רעיון חדשני לכדי מוצר היא שאלת ההגנה המשפטית. רישום פטנט הוא הפתרון שמקנה את ההגנה הרחבה ביותר אך הדרך להשגתו היא ארוכה ויקרה. הגם שלא כל אמצאה עומדת בתנאי הזכאות לרישום פטנט. רישום סימן מסחר היא אופציה זמינה נוספת ואם המוצר שלכם הוא דיגיטלי, כדאי שתכירו את חוק זכויות היוצרים. ריכזנו עבורכם את כל הפרטים החשובים במאמר\u00A0הבא."
  },
  secP: {
      title: "תנאי סף לצורך רישום\u00A0פטנט\u00A0בישראל",
      p: [
          'הזרוע שאחראית על נושא הפטנטים במדינת ישראל נקראת "רשות הפטנטים" והיא נמצאת תחת תחום הסמכות של משרד המשפטים. טופס בקשה לרישום פטנט ניתן להוריד מאתר הרשות המקוון אבל לפני שממלאים, כדאי לבדוק את העמידה בתנאים ולהבין את\u00A0המשמעויות.',
          'החוק מעמיד שלושה תנאים מרכזיים לרישום פטנט. ראשית, ייחודיות. הדבר הראשון שהרשות בודקת הוא קיומם של פטנטים ומוצרים דומים בשוק. מומלץ לבצע חיפוש במאגרים השונים שזמינים ברשת אם כי יש לקחת בחשבון שהחיפוש הזה לא יהיה הכי מקיף. תוכלו להיעזר בחברות המנוסות בתהליך במידה ותרצו לבצע חיפוש יסודי יותר. המבדק הבא יהיה מבדק היישום. פטנט ניתן עבור חידושים שיש להם יישום פרקטי. לא ניתן לרשום חומר תיאורטי כפטנט שהרי זה כבר שייך לתחום האחריות של האקדמיה. התנאי השלישי והאחרון לרישום פטנט הנו תנאי החדשנות. בין אם מדובר במוצר או תהליך, על מנת שייחשב פטנט – הוא חייב להוות התקדמות ממשית\u00A0בתחומו.'
      ]
  },
  thrdP: {
      title: "תהליך רישום הפטנט",
      p: [
          "אם אכן הפטנט עומד בכל הקריטריונים לזכאות, ניתן להגיש בקשה לרישום.  בבקשה תידרשו לכתוב בצורה מדויקת וברורה את מהות המוצר ותוכנו כולל הסבר אודות הבעיה שהמוצר שלכם פותר והתייחסות לכלל המוצרים הקיימים בשוק שכבר נותנים מענה לבעיה. בכך, למעשה, אתם תנמקו את מידת חדשנותו של\u00A0המוצר. ",
          "לאחר פירוט המוצר והסבר אודותיו, תצטרכו לכתוב את תביעותיכם. התביעות הן למעשה הסעיפים הספציפיים עליהם אתם מבקשים את זכות הבכורה המשפטית. במהלך תהליך הבחינה של המשרד יתכן ותדרשו לבאר אי בהירויות. אי לכך נדרשת זמינותכם המלאה לתקשורת מול הרשות, או זמינות מיופה כוחכם, במידה ויהיה בכך צורך. מיופה כוח יכול להיות חברה שתנהל את התהליך\u00A0בעבורכם.   ",
          "לאחר אישור הפטנט ותשלום אגרת פרסומו, הוא מושק לציבור הרחב לתקופת בחינה של שלושה חודשים. כל גורם פרטי או מסחרי רשאי להביע את התנגדותו לרישום הפטנט. אם עולה התנגדות, היא נבחנת נקודתית. במידה ומתנגד נעתר, מגיש הבקשה עלול לשלם קנס. במידה ומגיש הבקשה נעתר, המתנגד עלול לשלם קנס. במידה ולא היה התנגדויות, בתום שלושת חודשי הבחינה, נרשם הפטנט המיוחל. צפו גם לאגרת חידוש רישום שתיגבה בהתאם לחוק בתדירות של אחת לכמה שנים, נכון להיום. החוק כמובן עלול\u00A0להשתנות."
      ]
  },
  frthP: {
      title: "אגרת רישום פטנט",
      p: "אגרת רישום פטנט – הכי מינימלי יעמוד על שניים עשר אלף שקלים לערך ויהיה תקף לעשרים שנים. בנוסף אליו ישנה אגרה עבור פתיחת הבקשה שעולה 1200 ₪ למי שמגיש בפעם הראשונה ו 2000 ₪ למי שכבר הגיש בקשה בעבר. כמה זמן לוקח לרשום פטנט? בכל רגע נתון עומדים בפני בחינה אלפי פטנטים ואי לכך ההמתנה לבחינת בקשה אורכת פרק זמן בלתי מבוטל, לעיתים שנים. מאחר ומדובר בתהליך בירוקרטי די יקר ולא כל כך פשוט, מומלץ להיעזר בחברה שתלווה אתכם\u00A0בתהליך."
  },
  ffthP: {
      title: "רישום פטנט באפליקציה",
      p: "במידה והאפליקציה מכילה חידוש טכנולוגי של ממש שעומד בתנאים שצוינו לעיל, תוכלו לשקול לרשום עליה פטנט. במידה ולא, היות וכאמור לא ניתן לבצע רישום פטנט על רעיון, ההמלצה המשפטית הרווחת היא תיעוד מדוקדק של תהליך פיתוח האפליקציה. זה יעזור לכם במידה ותדרשו לספק ראיות משפטיות על הקניין שלכם. בנוסף, כדאי ללוות את כל תהליך העבודה בהסכמי סודיות שיבטיחו את נאמנותם של שותפיכם, או לפחות יגבירו אותה משמעותית. עם תום תהליך הפיתוח כאשר קיים כבר מוצר הלכה למעשה, אתם מוגנים מתוקף חוק זכויות יוצרים. זה אומר שהאפליקציה הנה קניין רוחני שלכם. אם תרצו לנסות את מזלכם גם בשוק האמריקאי, מומלץ לרשום את הזכויות על היצירה שלכם אצל רשם זכויות היוצרים האמריקאי. את התהליך כדאי לעשות בליווי של חברה המנוסה\u00A0בתהליך. "
  },
  sixP: {
      title: "רישום סימן מסחר",
      p: [
          'סימן מסחר הידוע גם בכינויו Trademark, הנו סימן ויזואלי דו או תלת ממדי שמטרתו לבדל חברה או מוצר באופן גרפי. סימן המסחר הנו מתודה נוספת להבניית זהות ובידול והיתרון שלה לעומת רישום הפטנט הוא שהבניית הזהות הזו נעשית לעיניי הציבור. דוגמה טובה לכוחו של סימן המסחר היא למשל הסמל של קוקה קולה שמזוהה כאיכותי ביותר מבין סוגי משקאות הקולה. איש מהצרכנים אינו שואל את עצמו "מי היה קודם" או "על שם מי רשום הפטנט". סימן המסחר מבדל את המוצר מהמתחרים ומקל על הצרכן בבחירה.      ',
          'רשם סימני המסחר מצוי גם הוא תחת תחום סמכותה של רשות הפטנטים. תהליך הבחינה לרישום סימן מסחר לוקחת עד שנה. בדומה לתהליך בחינת פטנט, גם כאן יחל  התהליך מבדיקת ייחודיות אל מול מאגר סימני המסחר וגם כאן בסוף התהליך תהיה תקופת בחינה בת שלושה חודשים בה רשאי הציבור להתנגד לרישום הסימן. ההגנה שמקנה הרישום על הסימן תקפה לעשר שנים. לאחר מכן יש להגיש בקשה לחידוש ולשלם את האגרה שתהיה נקובה בחוק באותה עת. עלות אגרת הגשה לרישום סימן מסחר נעה בין סדר גודל של 1200 ₪ ל 1600 ₪ והיא משתנה בהתאם לתחום אליו משתייך המוצר או החברה. אגרות נוספות יידרשו במידה ותרצו להגיש סימן מסחר שתקף בחו"ל או לנסות לזרז\u00A0את\u00A0התהליך.'
      ]
  },
  dir: 'rtl'
};